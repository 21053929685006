<script>

import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import ApexChart from "apexcharts";
import $ from "jquery";
import Cookies from "js-cookie";

// import $ from "jquery";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      role_slug: Cookies.get("session_role_slug"),
      id_user: Cookies.get("session_id"),

      master_user: [],
      user_selected: "",
      user_id: 1,
      nama_pic: 'Smartsoft Studio',
      data_pic: [],
      dataKategoridanJumlahKonsultan: {
        labels: [],
        series: []
      },
      dataKategoridanJumlahKonsultanCancel: {
        labels: [],
        series: []
      },
      dataKategoridanJumlahVendor: {
        labels: [],
        series: []
      },
      dataKategoridanJumlahVendorCancel: {
        labels: [],
        series: []
      },
      dataPenilaianKonsultan: {
        labels: [],
        series: []
      },
      dataPenilaianVendor: {
        labels: [],
        series: []
      },
      dataKategoridanNominalKonsultan: {
        labels: [],
        series: []
      },
      dataKategoridanNominalVendor: {
        labels: [],
        series: []
      },
      dataPICVendor: {
        labels: [],
        series: []
      },
      dataEachPICVendor: {
        labels: [],
        series: []
      },
      options: {
        seriesBarDistance: 10,
        reverseData: true,
        horizontalBars: true,
        axisY: {
          offset: 100,
          showGrid: true,
        },

        axisX: {
          labelInterpolationFnc: (value) => {
            if (value > Math.floor(value)) {
              //console.log();
            } else {
              return value;
            }
            // return (value / 1) + 'k';
          },
        },

        height: 300
      },
      options_nominal: {
        seriesBarDistance: 10,
        reverseData: true,
        horizontalBars: true,
        axisY: {
          offset: 100,
          showGrid: true,
        },

        axisX: {
          labelInterpolationFnc: (value) => {
            if (value % 1000000 == 0) {
              return (value / 1000000) + ' Juta';
            }
          },
        },

        height: 300
      },
      
      chartLoadingPIC: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showModal = true;
    }, 1500);
    this.getDashboardVendor();
    this.getChartPIC();
    this.getPIC();
  },
  methods: {
    async getDashboardVendor() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      // let self = this;
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/home/dashboard-vendor"
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.code == 200) {

            // Kategori Dan Jumlah Konsultan
            var json_categories = response_data_fix.kategoriDanJumlah.consultant;
            let categories_1 = [];
            let data_categories_1_acc = [];
            let data_categories_1_cancel = [];
            let data_categories_1_nominal = [];
            let data_categories_final_1 = [];
            let data_categories_final_final_1 = [];
            let data_categories_final_nominal = [];
            $.each(json_categories, function (indexInArray, valueOfElement) {
              data_categories_1_acc.push(valueOfElement.acc);
              data_categories_1_cancel.push(valueOfElement.cancel);
              data_categories_1_nominal.push(valueOfElement.nominal);
              categories_1.push(indexInArray);
            });
            data_categories_final_1.push(data_categories_1_acc);
            data_categories_final_final_1.push(data_categories_1_acc);
            data_categories_final_final_1.push(data_categories_1_cancel);
            data_categories_final_nominal.push(data_categories_1_nominal);

            this.dataKategoridanJumlahKonsultan.labels = categories_1;
            this.dataKategoridanJumlahKonsultan.series = data_categories_final_1;

            this.dataKategoridanJumlahKonsultanCancel.labels = categories_1;
            this.dataKategoridanJumlahKonsultanCancel.series = data_categories_final_final_1;
            
            this.dataKategoridanNominalKonsultan.labels = categories_1;
            this.dataKategoridanNominalKonsultan.series = data_categories_final_nominal;

            // Kategori Dan Jumlah Vendor
            var json_categories_2 = response_data_fix.kategoriDanJumlah.vendor;
            let categories_2 = [];
            let data_categories_2_acc = [];
            let data_categories_2_cancel = [];
            let data_categories_2_nominal = [];
            let data_categories_final_2 = [];
            let data_categories_final_final_2 = [];
            let data_categories_final_nominal_2 = [];
            $.each(json_categories_2, function (indexInArray, valueOfElement) {
              data_categories_2_acc.push(valueOfElement.acc);
              data_categories_2_cancel.push(valueOfElement.cancel);
              data_categories_2_nominal.push(valueOfElement.nominal);
              categories_2.push(indexInArray);
            });
            data_categories_final_2.push(data_categories_2_acc);
            data_categories_final_final_2.push(data_categories_2_acc);
            data_categories_final_final_2.push(data_categories_2_cancel);
            data_categories_final_nominal_2.push(data_categories_2_nominal);

            this.dataKategoridanJumlahVendor.labels = categories_2;
            this.dataKategoridanJumlahVendor.series = data_categories_final_2;

            this.dataKategoridanJumlahVendorCancel.labels = categories_2;
            this.dataKategoridanJumlahVendorCancel.series = data_categories_final_final_2;

            this.dataKategoridanNominalVendor.labels = categories_2;
            this.dataKategoridanNominalVendor.series = data_categories_final_nominal_2;

            // Penilaian Konsultan
            var json_categories_3 = response_data_fix.kategoriDanJumlah.consultant;
            let categories_3 = [];
            let data_categories_vg = [];
            let data_categories_g = [];
            let data_categories_n = [];
            let data_categories_b = [];
            // let data_categories_each_final = [];
            // let data_categories_3_cancel = [];
            let data_categories_final_3 = [];
            $.each(json_categories_3, function (indexInArray, valueOfElement) {
              categories_3.push(indexInArray);
              data_categories_vg.push(valueOfElement.penilaian['Very Good']);
              data_categories_g.push(valueOfElement.penilaian.Good);
              data_categories_n.push(valueOfElement.penilaian.Netral);
              data_categories_b.push(valueOfElement.penilaian.Bad);
            });
            
            data_categories_final_3.push(data_categories_vg);
            data_categories_final_3.push(data_categories_g);
            data_categories_final_3.push(data_categories_n);
            data_categories_final_3.push(data_categories_b);

            this.dataPenilaianKonsultan.labels = categories_3;
            this.dataPenilaianKonsultan.series = data_categories_final_3;

            // Penilaian Konsultan
            var json_categories_4 = response_data_fix.kategoriDanJumlah.vendor;
            let categories_4 = [];
            let data_categories_vg_1 = [];
            let data_categories_g_1 = [];
            let data_categories_n_1 = [];
            let data_categories_b_1 = [];
            // let data_categories_each_final = [];
            // let data_categories_3_cancel = [];
            let data_categories_final_4 = [];
            $.each(json_categories_4, function (indexInArray, valueOfElement) {
              categories_4.push(indexInArray);
              data_categories_vg_1.push(valueOfElement.penilaian['Very Good']);
              data_categories_g_1.push(valueOfElement.penilaian.Good);
              data_categories_n_1.push(valueOfElement.penilaian.Netral);
              data_categories_b_1.push(valueOfElement.penilaian.Bad);
            });
            
            data_categories_final_4.push(data_categories_vg_1);
            data_categories_final_4.push(data_categories_g_1);
            data_categories_final_4.push(data_categories_n_1);
            data_categories_final_4.push(data_categories_b_1);

            this.dataPenilaianVendor.labels = categories_4;
            this.dataPenilaianVendor.series = data_categories_final_4;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
          Swal.close();
        });
    },
    getChartPIC() {
      this.chartLoadingPIC = true;
      $('#chartTotalVendor').html('<div></div>');
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/home/dashboard-vendor-pic?pic=" + this.user_id
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.code == 200) {
            // Total Sponsor Yang Diprakasai Oleh Pekerja
            var json_categories = response_data_fix.picKonsultanDanVendor;
            let categories_1 = [];
            let data_categories_jumlah = [];
            let data_categories_nominal = [];
            let data_categories_final_1 = [];
            let data_categories_final_nominal_1 = [];
            let pic_data = [];
            $.each(json_categories, function (indexInArray, valueOfElement) {
              pic_data = valueOfElement;
              $.each(valueOfElement, function (index, value) {
                categories_1.push(index);
                data_categories_jumlah.push(value.jumlah);
                data_categories_nominal.push(value.nominal);
              });
            });
            this.data_pic = pic_data;
            data_categories_final_1.push(data_categories_jumlah);
            this.dataPICVendor.labels = categories_1;
            this.dataPICVendor.series = data_categories_final_1;


            data_categories_final_nominal_1.push(data_categories_nominal);
            //console.log(data_categories_final_nominal_1);
            this.dataEachPICVendor.labels = categories_1;
            this.dataEachPICVendor.series = data_categories_final_nominal_1;

            // Total Sponsor Yang Diprakasai Oleh Pekerja
            let chartInstance;
            const options = {
              chart: {
                width: 380,
                type: "donut",
              },
              labels: categories_1,
              // colors: ["#34c38f", "#556ee6", "#f46a6a"],
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
              },
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    chart: {
                      height: 240,
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
            };
            const getChartData = () => data_categories_jumlah;
            chartInstance = new ApexChart(document.querySelector("#chartTotalVendor"), {
              ...options,
              series: getChartData(),
            });
            chartInstance.render();
            this.chartLoadingPIC = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getPIC() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/master/user/pic"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_user = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    userSelected() {
      this.user_id = this.user_selected?.id;
      this.nama_pic = this.user_selected?.name;
      this.getChartPIC();
    },
    numberFormat(bilangan){
        var number_string = bilangan.toString(),
            sisa         = number_string.length % 3,
            rupiah         = number_string.substr(0, sisa),
            ribuan         = number_string.substr(sisa).match(/\d{3}/g);
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        
        return rupiah;
    },
  }
};
</script>
<style>
.grid-margin {
  margin-bottom: 2.5rem;
}

@media (min-width: 576px) {
  .grid-margin-sm-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .grid-margin-md-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .grid-margin-lg-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .grid-margin-xl-0 {
    margin-bottom: 0;
  }
}

.stretch-card {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: stretch;
  justify-content: stretch;
}

.stretch-card>.card {
  width: 100%;
  min-width: 100%;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
}

.card.card-light-danger {
  background: #f3797e;
  color: #ffffff;
}

.card.card-light-danger:hover {
  background: #f59095;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body2 {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card.tale-bg {
  background: #dae7ff;
}

.card.card-tale {
  background: #439ffa;
  color: #ffffff;
}

.card.card-tale:hover {
  background: #0b7ee6 !important;
}

.card-people {
  position: relative;
  padding-top: 20px;
}

.card-people img {
  border-radius: 20px;
  width: 100%;
}

.card-people .logo {
  border-radius: 0px;
  width: 100%;
}

.mb-lg-0,
.my-lg-0 {
  margin-bottom: 0 !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.card.transparent {
  background: transparent;
}

.card .card-body2 {
  padding: 1.25rem 1.25rem;
}

.card .card-body2+.card-body2 {
  padding-top: 1rem;
}

.card.card-dark-blue {
  background: #439ffa;
  color: #ffffff;
}

.card.card-dark-blue:hover {
  background: #0b7ee6 !important;
}

.card.card-light-blue {
  background: #439ffa;
  color: #ffffff;
}

.card.card-light-blue:hover {
  background: #0b7ee6 !important;
}

.mb-1,
.my-4 {
  margin-bottom: 0.5rem !important;
}

.fs-30 {
  font-size: 30px;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.ml-2,
.btn-toolbar .btn-group+.btn-group,
.btn-toolbar .fc .fc-button-group+.btn-group,
.fc .btn-toolbar .fc-button-group+.btn-group,
.btn-toolbar .fc .btn-group+.fc-button-group,
.fc .btn-toolbar .btn-group+.fc-button-group,
.btn-toolbar .fc .fc-button-group+.fc-button-group,
.fc .btn-toolbar .fc-button-group+.fc-button-group,
.rtl .settings-panel .events i,
.mx-2 {
  margin-left: 0.5rem !important;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}
</style>
<template>
  <div class="row">
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kategori Dan Jumlah Konsultan</p>
          </div>
          <chartist ratio="ct-chart" :data="dataKategoridanJumlahKonsultan" :options="options" type="Bar">
          </chartist>
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kategori Dan Jumlah Vendor</p>
          </div>
          <chartist ratio="ct-chart" :data="dataKategoridanJumlahVendor" :options="options" type="Bar">
          </chartist>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kategori Dan Jumlah Konsultan Acc / Cancel</p>
          </div>
          <chartist ratio="ct-chart" :data="dataKategoridanJumlahKonsultanCancel" :options="options" type="Bar">
          </chartist>
          <div style="display: table;">
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #34c38f !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Jumlah Konsultan &nbsp;
            </div>
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #556ee6 !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Jumlah cancel &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kategori Dan Jumlah Vendor Acc / Cancel</p>
          </div>
          <chartist ratio="ct-chart" :data="dataKategoridanJumlahVendorCancel" :options="options" type="Bar">
          </chartist>
          <div style="display: table;">
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #34c38f !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Jumlah Vendor &nbsp;
            </div>
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #556ee6 !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Jumlah cancel &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Penilaian Konsultan</p>
          </div>
          <chartist ratio="ct-chart" :data="dataPenilaianKonsultan" :options="options" type="Bar">
          </chartist>
          
          <div style="display: table;">
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #f46a6a !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Very Good &nbsp;
            </div>
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #f1b44c !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Good &nbsp;
            </div>
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #34c38f !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Netral &nbsp;
            </div>
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #556ee6 !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Bad &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Penilaian Vendor</p>
          </div>
          <chartist ratio="ct-chart" :data="dataPenilaianVendor" :options="options" type="Bar">
          </chartist>
          <div style="display: table;">
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #f46a6a !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Very Good &nbsp;
            </div>
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #f1b44c !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Good &nbsp;
            </div>
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #34c38f !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Netral &nbsp;
            </div>
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #556ee6 !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Bad &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kategori Dan Nominal Konsultan</p>
          </div>
          <chartist ratio="ct-chart" :data="dataKategoridanNominalKonsultan" :options="options_nominal" type="Bar">
          </chartist>
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kategori Dan Nominal Vendor</p>
          </div>
          <chartist ratio="ct-chart" :data="dataKategoridanNominalVendor" :options="options_nominal" type="Bar">
          </chartist>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Jumlah Vendor / Konsultan Per PIC</p>
          </div>
          <div class="row mb-2">
            <v-select placeholder="Pilih PIC" :options="master_user" label="name" v-model="user_selected"
              @update:modelValue="userSelected"></v-select>
          </div>
          <div class="d-flex justify-content-between">
            <p class="card-title">PIC Vendor : {{ nama_pic }}</p>
          </div>
          <chartist ratio="ct-chart" :data="dataPICVendor" :options="options" type="Bar"></chartist>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Total PIC Sesuai Nama User : {{ nama_pic }}</p>
          </div>
          <div v-if="chartLoadingPIC">
            <i class="fas fa-spinner fa-spin"></i> Loading...
          </div>
          <center>
            <div id="chartTotalVendor"></div>
          </center>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Jumlah Vendor / Konsultan Per PIC</p>
          </div>
          <div v-if="chartLoadingPIC">
            <i class="fas fa-spinner fa-spin"></i> Loading...
          </div>
          <div v-else>
            <chartist ratio="ct-chart" :data="dataEachPICVendor" :options="options_nominal" type="Bar"></chartist>
            <table class="table mb-0 table-bordered table-condensed table-hover" id="myTable">
              <thead class="text-center text-white" style="background-color: #132d4a">
                <tr>
                  <th>#</th>
                  <th>{{ nama_pic }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="chartLoadingPIC">
                  <td colspan="2">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </td>
                </tr>
                <tr v-else-if="data_pic == ''">
                  <td class="text-center" colspan="6">
                    Data Tidak Tersedia
                  </td>
                </tr>
                <tr v-else v-for="(row_data, key_data) in data_pic" :key="key_data">
                  <td>
                    {{ key_data }}
                  </td>
                  <td>
                    Rp. {{ numberFormat(row_data.nominal)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
