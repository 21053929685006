<script>
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import ApexChart from "apexcharts";
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      role_slug: Cookies.get("session_role_slug"),
      id_user: Cookies.get("session_id"),
      master_klasifikasi: [],
      klasifikasi_selected: "",
      klasifikasi_id: 3,

      master_user: [],
      user_selected: "",
      user_id: 1,
      nama_pic: 'Smartsoft Studio',
      data_pic: [],
      chartLoading: true,
      chartLoadingPIC: true,
      dataKategoridanJumlahSponsor: {
        labels: [],
        series: []
      },
      dataKategoriDanJumlahSponsorCancelDanDiberikan: {
        labels: [],
        series: []
      },
      dataKantorKategoridanJumlahSponsor: {
        labels: [],
        series: []
      },
      dataKategoridanNominalSponsor: {
        labels: [],
        series: []
      },
      dataLogodanNominalSponsor: {
        labels: [],
        series: []
      },
      dataJenisKegiatandanJumlahSponsor: {
        labels: [],
        series: []
      },
      dataPICSponsorship: {
        labels: [],
        series: []
      },
      dataEachPICSponsorship: {
        labels: [],
        series: []
      },
      options: {
        seriesBarDistance: 10,
        reverseData: true,
        horizontalBars: true,
        axisY: {
          offset: 100,
          showGrid: true,
        },

        axisX: {
          labelInterpolationFnc: (value) => {
            if (value > Math.floor(value)) {
              //console.log();
            } else {
              return value;
            }
            // return (value / 1) + 'k';
          },
        },

        height: 300
      },
      options_horizontal: {
        seriesBarDistance: 10,
        axisX: {
          showGrid: true,
        },

        axisY: {
          labelInterpolationFnc: (value) => {
            if (value > Math.floor(value)) {
              //console.log();
            } else {
              return value;
            }
            // return (value / 1) + 'k';
          },
        },

        height: 300
      },
      options_nominal: {
        seriesBarDistance: 10,
        reverseData: true,
        horizontalBars: true,
        axisY: {
          offset: 100,
          showGrid: true,
        },

        axisX: {
          labelInterpolationFnc: (value) => {
            if (value % 1000000 == 0) {
              return (value / 1000000) + ' Juta';
            }
          },
        },

        height: 300
      },
    };
  },
  mounted() {
    this.getDashboardSponsorship();
    this.getChartKegiatan();
    this.getChartPIC();
    this.getKlasifikasi();
    this.getPIC();
  },
  methods: {
    async getDashboardSponsorship() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      // let self = this;
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/home/dashboard-sponsorship?status_sponsorship=bagian-1"
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.code == 200) {
            // Kategori Dan Jumlah Sponsor
            var json_categories = response_data_fix.kategoriDanJumlahSponsor;
            let categories_1 = [];
            let data_categories_1 = [];
            let data_categories_final_1 = [];
            $.each(json_categories, function (indexInArray, valueOfElement) {
              data_categories_1.push(valueOfElement);
              categories_1.push(indexInArray);
            });
            data_categories_final_1.push(data_categories_1);
            this.dataKategoridanJumlahSponsor.labels = categories_1;
            this.dataKategoridanJumlahSponsor.series = data_categories_final_1;

            // Kategori Dan Jumlah Sponsor Cancel Dan Diberikan
            var json_categories_2 = response_data_fix.kategoriDanJumlahSponsorCancelDanDiberikan;
            let categories_2 = [];
            let data_categories_2_cancel = [];
            let data_categories_2_terlaksana = [];
            let data_categories_final_2 = [];
            $.each(json_categories_2, function (indexInArray, valueOfElement) {
              data_categories_2_cancel.push(valueOfElement.cancel);
              data_categories_2_terlaksana.push(valueOfElement.terlaksana);
              categories_2.push(indexInArray);
            });
            data_categories_final_2.push(data_categories_2_terlaksana);
            data_categories_final_2.push(data_categories_2_cancel);
            this.dataKategoriDanJumlahSponsorCancelDanDiberikan.labels = categories_2;
            this.dataKategoriDanJumlahSponsorCancelDanDiberikan.series = data_categories_final_2;

            // Kantor Wilayah + KCK : Kategori Dan Nominal Sponsor
            var json_categories_3 = response_data_fix.kantorDanNominalSponsor;
            let categories_3 = [];
            let data_categories_3 = [];
            let data_categories_final_3 = [];
            $.each(json_categories_3, function (indexInArray, valueOfElement) {
              data_categories_3.push(valueOfElement);
              categories_3.push(indexInArray);
            });
            data_categories_final_3.push(data_categories_3);
            this.dataKantorKategoridanJumlahSponsor.labels = categories_3;
            this.dataKantorKategoridanJumlahSponsor.series = data_categories_final_3;

            // Kategori Dan Nominal Sponsor
            var json_categories_4 = response_data_fix.kategoriDanNominalSponsor;
            let categories_4 = [];
            let data_categories_4 = [];
            let data_categories_final_4 = [];
            $.each(json_categories_4, function (indexInArray, valueOfElement) {
              data_categories_4.push(valueOfElement);
              categories_4.push(indexInArray);
            });
            data_categories_final_4.push(data_categories_4);
            this.dataKategoridanNominalSponsor.labels = categories_4;
            this.dataKategoridanNominalSponsor.series = data_categories_final_4;

            // Pencantuman Logo Dan Jumlah Sponsor Diberikan
            var json_categories_5 = response_data_fix.logoDanNominalSponsor;
            let categories_5 = [];
            let data_categories_5 = [];
            let data_categories_final_5 = [];
            $.each(json_categories_5, function (indexInArray, valueOfElement) {
              data_categories_5.push(valueOfElement);
              categories_5.push(indexInArray);
            });
            data_categories_final_5.push(data_categories_5);
            this.dataLogodanNominalSponsor.labels = categories_5;
            this.dataLogodanNominalSponsor.series = data_categories_final_5;

            // Kategori Dan Jumlah Sponsor Donut
            let chartInstance;
            const options_sifat = {
              chart: {
                width: 300,
                type: "donut",
              },
              labels: categories_1,
              // colors: ["#34c38f", "#556ee6", "#f46a6a"],
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
              },
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    chart: {
                      height: 240,
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
            };

            const getChartData = () => data_categories_1;
            chartInstance = new ApexChart(document.querySelector("#chartKategoridanJumlahSponsor"), {
              ...options_sifat,
              series: getChartData(),
            });
            chartInstance.render();

            // Kategori Dan Jumlah Sponsor Donut
            let chartInstance_1;
            const options_sifat_1 = {
              chart: {
                width: 300,
                type: "donut",
              },
              labels: categories_4,
              // colors: ["#34c38f", "#556ee6", "#f46a6a"],
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
              },
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    chart: {
                      height: 240,
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
            };

            const getChartData_1 = () => data_categories_4;
            chartInstance_1 = new ApexChart(document.querySelector("#chartKategoridanNominalSponsor"), {
              ...options_sifat_1,
              series: getChartData_1(),
            });
            chartInstance_1.render();

            // Kategori Dan Jumlah Sponsor Donut
            let chartInstance_2;
            const options_sifat_2 = {
              chart: {
                width: 300,
                type: "donut",
              },
              labels: categories_3,
              colors: ["#392e4a", "#c9f29b", "#67f2d1", "#ecd9dd", "#ede99d", "#e33d94", "#5a228b", "#e3ba8f", "#16453e", "#d2ee82", "#a6915c", "#fffc7f", "#043b5c", "#b2de27", "#cf2f74", "#b7c1ac", "#e8d6cb", "#ff9470", "#c6eae3", "#93faa5"],
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
              },
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    chart: {
                      height: 240,
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
            };

            const getChartData_2 = () => data_categories_3;
            chartInstance_2 = new ApexChart(document.querySelector("#chartKantorKategoridanNominalSponsor"), {
              ...options_sifat_2,
              series: getChartData_2(),
            });
            chartInstance_2.render();

            // Pencantuman Logo Dan Jumlah
            let chartInstance_3;
            const options_sifat_3 = {
              chart: {
                width: 380,
                type: "donut",
              },
              labels: categories_5,
              colors: ["#392e4a", "#c9f29b", "#67f2d1", "#ecd9dd", "#ede99d", "#e33d94", "#5a228b", "#e3ba8f", "#16453e", "#d2ee82", "#a6915c", "#fffc7f", "#043b5c", "#b2de27", "#cf2f74", "#b7c1ac", "#e8d6cb", "#ff9470", "#c6eae3", "#93faa5"],
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
              },
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    chart: {
                      height: 240,
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
            };

            const getChartData_3 = () => data_categories_5;
            chartInstance_3 = new ApexChart(document.querySelector("#chartLogodanNominalSponsor"), {
              ...options_sifat_3,
              series: getChartData_3(),
            });
            chartInstance_3.render();

          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
          Swal.close();
        });
    },

    getChartKegiatan() {
      this.chartLoading = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/home/dashboard-sponsorship-chart-jenis-kegiatan-dan-jumlah-sponsor?jenis_kegiatan=" + this.klasifikasi_id
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.code == 200) {
            // Jenis Kegiatan Dan Jumlah Sponsor Sponsor
            var json_categories = response_data_fix.jenisKegiatanDanJumlahSponsor;
            let categories_1 = [];
            let data_categories_1 = [];
            let data_categories_final_1 = [];
            $.each(json_categories, function (indexInArray, valueOfElement) {
              $.each(valueOfElement, function (index, value) {
                data_categories_1.push(value);
                categories_1.push(index);
              });
            });
            data_categories_final_1.push(data_categories_1);
            this.dataJenisKegiatandanJumlahSponsor.labels = categories_1;
            this.dataJenisKegiatandanJumlahSponsor.series = data_categories_final_1;

            // Jenis Kegiatan Dan Jumlah Sponsor Sponsor Donut
            let chartInstance_kegiatan;
            const options_sifat_kegiatan = {
              chart: {
                width: 380,
                type: "donut",
              },
              labels: categories_1,
              // colors: ["#34c38f", "#556ee6", "#f46a6a"],
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
              },
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    chart: {
                      height: 240,
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
            };
            const getChartData_kegiatan = () => data_categories_1;
            chartInstance_kegiatan = new ApexChart(document.querySelector("#chartKegiatandanJumlahSponsor"), {
              ...options_sifat_kegiatan,
              series: getChartData_kegiatan(),
            });
            chartInstance_kegiatan.render();
            this.chartLoading = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    getChartPIC() {
      this.chartLoadingPIC = true;
      $('#chartTotalSponsorPIC').html('<div></div>');
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/home/dashboard-sponsorship-chart-pic-sponsorship?pic=" + this.user_id
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.code == 200) {
            // Total Sponsor Yang Diprakasai Oleh Pekerja
            var json_categories = response_data_fix.pic_sponsorship;
            let categories_1 = [];
            let data_categories_jumlah = [];
            let data_categories_nominal = [];
            let data_categories_final_1 = [];
            let data_categories_final_nominal_1 = [];
            let pic_data = [];
            $.each(json_categories, function (indexInArray, valueOfElement) {
              pic_data = valueOfElement;
              $.each(valueOfElement, function (index, value) {
                categories_1.push(index);
                data_categories_jumlah.push(value.jumlah);
                data_categories_nominal.push(value.total_nominal);
              });
            });
            
            this.data_pic = pic_data;
            data_categories_final_1.push(data_categories_jumlah);
            this.dataPICSponsorship.labels = categories_1;
            this.dataPICSponsorship.series = data_categories_final_1;


            data_categories_final_nominal_1.push(data_categories_nominal);
            this.dataEachPICSponsorship.labels = categories_1;
            this.dataEachPICSponsorship.series = data_categories_final_nominal_1;

            // Total Sponsor Yang Diprakasai Oleh Pekerja
            let chartInstance;
            const options = {
              chart: {
                width: 380,
                type: "donut",
              },
              labels: categories_1,
              // colors: ["#34c38f", "#556ee6", "#f46a6a"],
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
              },
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    chart: {
                      height: 240,
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
            };
            const getChartData = () => data_categories_jumlah;
            chartInstance = new ApexChart(document.querySelector("#chartTotalSponsorPIC"), {
              ...options,
              series: getChartData(),
            });
            chartInstance.render();
            this.chartLoadingPIC = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    getKlasifikasi() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/master/klasifikasi?status=ENABLE"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_klasifikasi = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    getPIC() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/master/user/pic"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_user = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    klasifikasiSelected() {
      this.klasifikasi_id = this.klasifikasi_selected?.id;
      this.getChartKegiatan();
    },

    userSelected() {
      this.user_id = this.user_selected?.id;
      this.nama_pic = this.user_selected?.name;
      this.getChartPIC();
    },
    
    numberFormat(bilangan){
        var number_string = bilangan.toString(),
            sisa         = number_string.length % 3,
            rupiah         = number_string.substr(0, sisa),
            ribuan         = number_string.substr(sisa).match(/\d{3}/g);
        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        
        return rupiah;
    },
  }
};
</script>
<style>
.grid-margin {
  margin-bottom: 2.5rem;
}

@media (min-width: 576px) {
  .grid-margin-sm-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .grid-margin-md-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .grid-margin-lg-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .grid-margin-xl-0 {
    margin-bottom: 0;
  }
}

.stretch-card {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: stretch;
  justify-content: stretch;
}

.stretch-card>.card {
  width: 100%;
  min-width: 100%;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e3e3;
  border-radius: 20px;
}

.card.card-light-danger {
  background: #f3797e;
  color: #ffffff;
}

.card.card-light-danger:hover {
  background: #f59095;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body2 {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card.tale-bg {
  background: #dae7ff;
}

.card.card-tale {
  background: #439ffa;
  color: #ffffff;
}

.card.card-tale:hover {
  background: #0b7ee6 !important;
}

.card-people {
  position: relative;
  padding-top: 20px;
}

.card-people img {
  border-radius: 20px;
  width: 100%;
}

.card-people .logo {
  border-radius: 0px;
  width: 100%;
}

.mb-lg-0,
.my-lg-0 {
  margin-bottom: 0 !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.card.transparent {
  background: transparent;
}

.card .card-body2 {
  padding: 1.25rem 1.25rem;
}

.card .card-body2+.card-body2 {
  padding-top: 1rem;
}

.card.card-dark-blue {
  background: #439ffa;
  color: #ffffff;
}

.card.card-dark-blue:hover {
  background: #0b7ee6 !important;
}

.card.card-light-blue {
  background: #439ffa;
  color: #ffffff;
}

.card.card-light-blue:hover {
  background: #0b7ee6 !important;
}

.mb-1,
.my-4 {
  margin-bottom: 0.5rem !important;
}

.fs-30 {
  font-size: 30px;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.ml-2,
.btn-toolbar .btn-group+.btn-group,
.btn-toolbar .fc .fc-button-group+.btn-group,
.fc .btn-toolbar .fc-button-group+.btn-group,
.btn-toolbar .fc .btn-group+.fc-button-group,
.fc .btn-toolbar .btn-group+.fc-button-group,
.btn-toolbar .fc .fc-button-group+.fc-button-group,
.fc .btn-toolbar .fc-button-group+.fc-button-group,
.rtl .settings-panel .events i,
.mx-2 {
  margin-left: 0.5rem !important;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}
</style>
<template>
  <div class="row">
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kategori Dan Jumlah Sponsor</p>
          </div>
          <chartist ratio="ct-chart" :data="dataKategoridanJumlahSponsor" :options="options" type="Bar"></chartist>
          <div style="display: table;">
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #556ee6 !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Jumlah sponsor diberikan
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kategori Dan Jumlah Sponsor Cancel Dan Diberikan</p>
          </div>
          <chartist ratio="ct-chart" :data="dataKategoriDanJumlahSponsorCancelDanDiberikan" :options="options" type="Bar">
          </chartist>
          <div style="display: table;">
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #556ee6 !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Jumlah sponsor diberikan &nbsp;
            </div>
            <div style="display: table-cell;">
              <div class="mr-3"
                style="background-color: #34c38f !important; width:13px; height:13px; border-radius: 50%;"></div>
            </div>
            <div style="display: table-cell;">
              &nbsp; Jumlah cancel &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kantor Wilayah + KCK : Kategori Dan Nominal Sponsor</p>
          </div>
          <chartist ratio="ct-chart" :data="dataKantorKategoridanJumlahSponsor" :options="options" type="Bar"></chartist>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kategori Dan Nominal Sponsor</p>
          </div>
          <chartist ratio="ct-chart" :data="dataKategoridanNominalSponsor" :options="options_nominal" type="Bar">
          </chartist>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kategori Dan Jumlah Sponsor</p>
          </div>
          <div id="chartKategoridanJumlahSponsor"></div>
        </div>
      </div>
    </div>
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kategori Dan Nominal Sponsor</p>
          </div>
          <div id="chartKategoridanNominalSponsor"></div>
        </div>
      </div>
    </div>
    <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Kantor Wilayah + KCK Kategori Dan Nominal Sponsor</p>
          </div>
          <div id="chartKantorKategoridanNominalSponsor"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Pencantuman Logo Dan Jumlah Sponsor Diberikan</p>
          </div>
          <chartist ratio="ct-chart" :data="dataLogodanNominalSponsor" :options="options" type="Bar"></chartist>
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Pencantuman Logo Dan Jumlah</p>
          </div>
          <center>
            <div id="chartLogodanNominalSponsor"></div>
          </center>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <v-select placeholder="Pilih Klasifikasi" :options="master_klasifikasi" label="klasifikasi"
              v-model="klasifikasi_selected" @update:modelValue="klasifikasiSelected"></v-select>
          </div>
          <div class="d-flex justify-content-between">
            <p class="card-title">Jenis Kegiatan Dan Jumlah Sponsor</p>
          </div>
          <div v-if="chartLoading">
            <i class="fas fa-spinner fa-spin"></i> Loading...
          </div>
          <div v-else>
            <center>
              <chartist ratio="ct-chart" :data="dataJenisKegiatandanJumlahSponsor" :options="options_horizontal"
                type="Bar"></chartist>
            </center>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Jenis Kegiatan Dan Jumlah Sponsor</p>
          </div>
          <div v-if="chartLoading">
            <i class="fas fa-spinner fa-spin"></i> Loading...
          </div>
          <div id="chartKegiatandanJumlahSponsor"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <v-select placeholder="Pilih PIC" :options="master_user" label="name" v-model="user_selected"
              @update:modelValue="userSelected"></v-select>
          </div>
          <div class="d-flex justify-content-between">
            <p class="card-title">PIC Sponsorship : {{ nama_pic }}</p>
          </div>
          <div v-if="chartLoadingPIC">
            <i class="fas fa-spinner fa-spin"></i> Loading...
          </div>
          <div v-else>
            <chartist ratio="ct-chart" :data="dataPICSponsorship" :options="options" type="Bar"></chartist>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Total Sponsor Yang Diprakasai Oleh Pekerja : {{ nama_pic }}</p>
          </div>
          <div v-if="chartLoadingPIC">
            <i class="fas fa-spinner fa-spin"></i> Loading...
          </div>
          <center>
            <div id="chartTotalSponsorPIC"></div>
          </center>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">Total Sponsor Masing Masing PIC</p>
          </div>
          <div v-if="chartLoadingPIC">
            <i class="fas fa-spinner fa-spin"></i> Loading...
          </div>
          <div v-else>
            <chartist ratio="ct-chart" :data="dataEachPICSponsorship" :options="options_nominal" type="Bar"></chartist>
            <table class="table mb-0 table-bordered table-condensed table-hover" id="myTable">
              <thead class="text-center text-white" style="background-color: #132d4a">
                <tr>
                  <th>#</th>
                  <th>{{ nama_pic }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="chartLoadingPIC">
                  <td colspan="2">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </td>
                </tr>
                <tr v-else-if="data_pic == ''">
                  <td class="text-center" colspan="6">
                    Data Tidak Tersedia
                  </td>
                </tr>
                <tr v-else v-for="(row_data, key_data) in data_pic" :key="key_data">
                  <td>
                    {{ key_data }}
                  </td>
                  <td>
                    Rp. {{ numberFormat(row_data.total_nominal)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
