<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import ApexChart from "apexcharts";
import DashboardSponsorshipView from "./dashboard-sponsorship.vue";
import DashboardVendorView from "./dashboard-vendor.vue";
import $ from "jquery";
import Cookies from "js-cookie";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    DashboardSponsorshipView,
    DashboardVendorView,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Default",
          active: true,
        },
      ],
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      useSlot: false,
      loader: "spinner",
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      timeout: 3000, //ms
      fetchingStats: true,
      earningStatus: true,
      show_session: '',
      suratmasuk: 0,
      suratkeluar: 0,
      telahdisposisi: 0,
      notulenradisi: 0,
      chartsuratmasuk: [],
      chart_surat_keluar: [],
      chart_sifat: [],
      chart_bentuk: [],
      sifat: [],
      jumlahsifat: [],
      bentuk: [],
      jumlahbentuk: [],
      namadepartemen: [],
      jumlahdepartemen: [],
      jumlahdepartemen_keluar: [],
      role_slug: Cookies.get("session_role_slug"),
      id_user: Cookies.get("session_id"),
      logo: Cookies.get("LOGO"),
      dashboardArsip: true,
      dashboardSponsorship: false,
      dashboardVendor: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showModal = true;
    }, 1500);

    this.getDashboardArsip();
  },
  methods: {
    async getDashboardArsip() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      let self = this;
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/home/dashboard-arsip?role_slug=" +
          self.role_slug +
          "&id_session_user=" +
          self.id_user +
          "&status_sponsorship=bagian-1"
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.code == 200) {
            self.suratmasuk = response_data_fix.suratmasuk;
            self.suratkeluar = response_data_fix.suratkeluar;
            self.telahdisposisi = response_data_fix.telahdisposisi;
            self.notulenradisi = response_data_fix.notulenradisi;
            self.chartsuratmasuk = response_data_fix.chartsuratmasuk;
            self.chart_surat_keluar = response_data_fix.chart_surat_keluar;
            self.chart_sifat = response_data_fix.chart_sifat;
            self.chart_bentuk = response_data_fix.chart_bentuk;
            self.sifat = response_data_fix.sifat;
            self.jumlahsifat = response_data_fix.jumlahsifat;
            self.bentuk = response_data_fix.bentuk;
            self.jumlahbentuk = response_data_fix.jumlahbentuk;
            self.namadepartemen = response_data_fix.namadepartemen;
            self.jumlahdepartemen = response_data_fix.jumlahdepartemen;
            self.jumlahdepartemen_keluar = response_data_fix.jumlahdepartemen_keluar;

            let chartInstanceJumlahSuratBerdasarkanSifatKerahasiaan;
            const options_sifat = {
              chart: {
                width: 380,
                type: "donut",
              },
              labels: response_data_fix.chart_sifat.label,
              // colors: ["#34c38f", "#556ee6", "#f46a6a"],
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
              },
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    chart: {
                      height: 240,
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
            };

            const getChartData = () => response_data_fix.chart_sifat.data;
            chartInstanceJumlahSuratBerdasarkanSifatKerahasiaan = new ApexChart(document.querySelector("#chartJumlahSuratBerdasarkanSifatKerahasiaan"), {
              ...options_sifat,
              series: getChartData(),
            });
            chartInstanceJumlahSuratBerdasarkanSifatKerahasiaan.render();

            let chartInstanceJumlahSuratBerdasarkanBentukDokumen;
            const options_bentuk = {
              chart: {
                width: 380,
                type: "donut",
              },
              labels: response_data_fix.chart_bentuk.label,
              // colors: ["#34c38f", "#556ee6", "#f46a6a"],
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                floating: false,
                fontSize: "14px",
                offsetX: 0,
              },
              responsive: [
                {
                  breakpoint: 600,
                  options: {
                    chart: {
                      height: 240,
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
            };

            const getChartDataBentukDokumen = () => response_data_fix.chart_bentuk.data;
            chartInstanceJumlahSuratBerdasarkanBentukDokumen = new ApexChart(document.querySelector("#chartJumlahSuratBerdasarkanBentukDokumen"), {
              ...options_bentuk,
              series: getChartDataBentukDokumen(),
            });
            chartInstanceJumlahSuratBerdasarkanBentukDokumen.render();

            // SURAT MASUK
            let chartInstanceSuratMasuk;
            const options_masuk = {
              chart: {
                toolbar: {
                  show: false,
                },
                width: 800,
                type: "bar",
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "85%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              // colors: ["#55efc4", "#f7a35c", "#74b9ff", "#a29bfe", "#ff7675"],
              xaxis: {
                categories: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Des",
                ],
              },
              yaxis: {
                title: {
                  text: " (data)",
                  style: {
                    fontWeight: "500",
                  },
                },
              },
              grid: {
                borderColor: "#f1f1f1",
              },
              fill: {
                opacity: 1,
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val + " data";
                  },
                },
              },
            };

            const getChartDataSuratMasuk = () => response_data_fix.chart_surat_masuk;
            chartInstanceSuratMasuk = new ApexChart(document.querySelector("#chartSuratMasuk"), {
              ...options_masuk,
              series: getChartDataSuratMasuk(),
            });
            chartInstanceSuratMasuk.render();

            // SURAT KELUAR
            let chartInstanceSuratKeluar;
            const options_keluar = {
              chart: {
                toolbar: {
                  show: false,
                },
                width: 800,
                type: "bar",
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "85%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              // colors: ["#55efc4", "#f7a35c", "#74b9ff", "#a29bfe", "#ff7675"],
              xaxis: {
                categories: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Des",
                ],
              },
              yaxis: {
                title: {
                  text: " (data)",
                  style: {
                    fontWeight: "500",
                  },
                },
              },
              grid: {
                borderColor: "#f1f1f1",
              },
              fill: {
                opacity: 1,
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val + " data";
                  },
                },
              },
            };

            const getChartDataSuratKeluar = () => response_data_fix.chart_surat_keluar;
            chartInstanceSuratKeluar = new ApexChart(document.querySelector("#chartSuratKeluar"), {
              ...options_keluar,
              series: getChartDataSuratKeluar(),
            });
            chartInstanceSuratKeluar.render();

          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }

          Swal.close();
        });
    },

    dashboardClicked(tipe) {
      let self = this;
      if (tipe == 'arsip') {
        self.getDashboardArsip();
        self.dashboardArsip = true;
        self.dashboardSponsorship = false;
        self.dashboardVendor = false;
        $("#menu-Arsip").addClass("active");
        $("#menu-Sponsorship").removeClass("active");
        $("#menu-Vendor").removeClass("active");
      } else if (tipe == 'sponsorship') {
        // self.getDashboardArsip();
        self.dashboardArsip = false;
        self.dashboardSponsorship = true;
        self.dashboardVendor = false;
        $("#menu-Sponsorship").addClass("active");
        $("#menu-Arsip").removeClass("active");
        $("#menu-Vendor").removeClass("active");
      } else {
        // self.getDashboardArsip();
        self.dashboardArsip = false;
        self.dashboardSponsorship = false;
        self.dashboardVendor = true;
        $("#menu-Vendor").addClass("active");
        $("#menu-Arsip").removeClass("active");
        $("#menu-Sponsorship").removeClass("active");
      }
      //console.log(tipe);
    }
  }
};
</script>
<style>
.grid-margin {
  margin-bottom: 2.5rem;
}

@media (min-width: 576px) {
  .grid-margin-sm-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .grid-margin-md-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .grid-margin-lg-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .grid-margin-xl-0 {
    margin-bottom: 0;
  }
}

.stretch-card {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-justify-content: stretch;
  justify-content: stretch;
}

.stretch-card>.card {
  width: 100%;
  min-width: 100%;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}

.card.card-light-danger {
  background: #f3797e;
  color: #ffffff;
}

.card.card-light-danger:hover {
  background: #f59095;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body2 {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card.tale-bg {
  background: #dae7ff;
}

.card.card-tale {
  background: #439ffa;
  color: #ffffff;
}

.card.card-tale:hover {
  background: #0b7ee6 !important;
}

.card.card-tale.active {
  background: #0b7ee6 !important;
}

.card-people {
  position: relative;
  padding-top: 20px;
}

.card-people img {
  border-radius: 5px;
  width: 100%;
}

.card-people .logo {
  border-radius: 0px;
  width: 100%;
}

.mb-lg-0,
.my-lg-0 {
  margin-bottom: 0 !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.card.transparent {
  background: transparent;
}

.card .card-body2 {
  padding: 1.25rem 1.25rem;
}

.card .card-body2+.card-body2 {
  padding-top: 1rem;
}

.card.card-dark-blue {
  background: #439ffa;
  color: #ffffff;
}

.card.card-dark-blue:hover {
  background: #0b7ee6 !important;
}

.card.card-light-blue {
  background: #439ffa;
  color: #ffffff;
}

.card.card-light-blue:hover {
  background: #0b7ee6 !important;
}

.mb-1,
.my-4 {
  margin-bottom: 0.5rem !important;
}

.fs-30 {
  font-size: 30px;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.ml-2,
.btn-toolbar .btn-group+.btn-group,
.btn-toolbar .fc .fc-button-group+.btn-group,
.fc .btn-toolbar .fc-button-group+.btn-group,
.btn-toolbar .fc .btn-group+.fc-button-group,
.fc .btn-toolbar .btn-group+.fc-button-group,
.btn-toolbar .fc .fc-button-group+.fc-button-group,
.fc .btn-toolbar .fc-button-group+.fc-button-group,
.rtl .settings-panel .events i,
.mx-2 {
  margin-left: 0.5rem !important;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}
</style>
<template>
  <Layout>
    <div
      class="row"
      style="margin-bottom: -50px !important;"
    >
      <div class="col-md-4 grid-margin">
        <div
          class="col-md-12 stretch-card transparent"
          @click="dashboardClicked('arsip')"
          style="cursor: pointer"
        >
          <div
            class="card card-tale"
            id="menu-Arsip"
          >
            <div class="card-body2">
              <p class="mb-0 text-muted">
                <img
                  src="@/assets/images/6950c16c9bcc6995f376b297f163175977872.png"
                  width="45"
                  height="45"
                />
                &emsp;<span
                  style="font-size: 16px"
                  class="text-white"
                >Dashboard Arsip</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 grid-margin">
        <div
          class="col-md-12 stretch-card transparent"
          @click="dashboardClicked('sponsorship')"
          style="cursor: pointer"
        >
          <div
            class="card card-tale"
            id="menu-Sponsorship"
          >
            <div class="card-body2">
              <p class="mb-0 text-muted">
                <img
                  src="@/assets/images/6950c16c9bcc6995f376b297f163175952749.png"
                  width="45"
                  height="45"
                />
                &emsp;<span
                  style="font-size: 16px"
                  class="text-white"
                >Dashboard Sponsorship</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 grid-margin">
        <div
          class="col-md-12 stretch-card transparent"
          @click="dashboardClicked('vendor')"
          style="cursor: pointer"
        >
          <div
            class="card card-tale"
            id="menu-Vendor"
          >
            <div class="card-body2">
              <p class="mb-0 text-muted">
                <img
                  src="@/assets/images/6950c16c9bcc6995f376b297f163175941101.png"
                  width="45"
                  height="45"
                />
                &emsp;<span
                  style="font-size: 16px"
                  class="text-white"
                >Dashboard Vendor/Konsultan</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      style="margin-bottom: -50px !important;"
    >
      <div class="col-md-12 col-xs-12 grid-margin transparent">
        <div
          class="row"
          style="height: 100%"
        >
          <div
            class="col-md-12 stretch-card transparent"
            style="height: inherit"
          >
            <div class="card p-4">
              <div
                class="card-body2"
                style="display: flex; align-items: center"
              >
                <div
                  class="row"
                  style="display: flex; align-items: center; width: 100%"
                >
                  <div class="col-md-4">
                    <img
                      :src="logo"
                      class="img-responsive"
                      style="width: 200px; margin: auto"
                    />
                  </div>
                  <div class="col-md-8">
                    <div>
                      <h4>
                        <b><span style="font-size: 25px">Platform Kerjamu, Digimon! <br />
                            (Digital Arsip & Monitoring Corporate Secretary
                            Division)</span></b>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dashboardArsip">
      <div
        class="row"
        style="margin-bottom: -50px !important;"
        v-if="role_slug == 'super_admin' || role_slug == 'sekretaris_divisi'"
      >
        <div class="col-md-3">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <router-link
                    :to="{ name: 'all-surat_masuk' }"
                    class="text-dark"
                  >
                    <p class="card-title mb-2">Surat Masuk</p>
                    <h5 class="mb-0 text-muted">
                      {{ suratmasuk }}
                    </h5>
                  </router-link>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-danger">
                  <span class="avatar-title"><i class="bx bx-envelope font-size-24"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <router-link
                    :to="{ name: 'all-surat_keluar' }"
                    class="text-dark"
                  >
                    <p class="card-title mb-2">Surat Keluar</p>
                    <h5 class="mb-0 text-muted">{{ suratkeluar }}</h5>
                  </router-link>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                  <span class="avatar-title"><i class="bx bx-mail-send font-size-24"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <router-link
                    :to="{ name: 'surat-masuk-didisposisikan' }"
                    class="text-dark"
                  >
                    <p class="card-title mb-2">Telah Disposisi</p>
                    <h5 class="mb-0 text-muted">{{ telahdisposisi }}</h5>
                  </router-link>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-info">
                  <span class="avatar-title"><i class="bx bx-check-double font-size-24"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <router-link
                    :to="{ name: 'notulen' }"
                    class="text-dark"
                  >
                    <p class="card-title mb-2">Notulen Radisi</p>
                    <h5 class="mb-0 text-muted">{{ notulenradisi }}</h5>
                  </router-link>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-warning">
                  <span class="avatar-title"><i class="bx bx-list-check font-size-24"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-12 grid-margin stretch-card"
          v-if="role_slug != 'officer'"
        >
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <p class="card-title">Jumlah Surat Masuk</p>
              </div>
              <center>
                <div id="chartSuratMasuk"></div>
              </center>
            </div>
          </div>
        </div>
        <div
          class="col-md-12 grid-margin stretch-card"
          v-if="role_slug != 'officer'"
        >
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <p class="card-title">Jumlah Surat Keluar</p>
              </div>
              <center>
                <div id="chartSuratKeluar"></div>
              </center>
            </div>
          </div>
        </div>

        <div
          class="col-md-6 grid-margin stretch-card"
          v-if="role_slug != 'officer'"
        >
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <p class="card-title">
                  Jumlah Surat Berdasarkan Sifat kerahasiaan
                </p>
              </div>
              <div id="chartJumlahSuratBerdasarkanSifatKerahasiaan"></div>
            </div>
          </div>
        </div>

        <div
          class="col-md-6 grid-margin stretch-card"
          v-if="role_slug != 'officer'"
        >
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <p class="card-title">Jumlah Surat Berdasarkan Bentuk Dokumen</p>
              </div>
              <div id="chartJumlahSuratBerdasarkanBentukDokumen"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        style="margin-bottom: -50px !important;"
        v-else
      >
        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <router-link
                    :to="{ name: 'all-surat_masuk' }"
                    class="text-dark"
                  >
                    <p class="card-title mb-2">Surat Masuk</p>
                    <h5 class="mb-0 text-muted">
                      {{ suratmasuk }}
                    </h5>
                  </router-link>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-danger">
                  <span class="avatar-title"><i class="bx bx-envelope font-size-24"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <router-link
                    :to="{ name: 'all-surat_keluar' }"
                    class="text-dark"
                  >
                    <p class="card-title mb-2">Surat Keluar</p>
                    <h5 class="mb-0 text-muted">{{ suratkeluar }}</h5>
                  </router-link>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                  <span class="avatar-title"><i class="bx bx-mail-send font-size-24"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <router-link
                    :to="{ name: 'surat-masuk-didisposisikan' }"
                    class="text-dark"
                  >
                    <p class="card-title mb-2">Telah Disposisi</p>
                    <h5 class="mb-0 text-muted">{{ telahdisposisi }}</h5>
                  </router-link>
                </div>
                <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-info">
                  <span class="avatar-title"><i class="bx bx-check-double font-size-24"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-12 grid-margin stretch-card"
          v-if="role_slug != 'officer'"
        >
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <p class="card-title">Jumlah Surat Masuk</p>
              </div>
              <center>
                <div id="chartSuratMasuk"></div>
              </center>
            </div>
          </div>
        </div>
        <div
          class="col-md-12 grid-margin stretch-card"
          v-if="role_slug != 'officer'"
        >
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <p class="card-title">Jumlah Surat Keluar</p>
              </div>
              <center>
                <div id="chartSuratKeluar"></div>
              </center>
            </div>
          </div>
        </div>

        <div
          class="col-md-6 grid-margin stretch-card"
          v-if="role_slug != 'officer'"
        >
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <p class="card-title">
                  Jumlah Surat Berdasarkan Sifat kerahasiaan
                </p>
              </div>
              <div id="chartJumlahSuratBerdasarkanSifatKerahasiaan"></div>
            </div>
          </div>
        </div>

        <div
          class="col-md-6 grid-margin stretch-card"
          v-if="role_slug != 'officer'"
        >
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <p class="card-title">Jumlah Surat Berdasarkan Bentuk Dokumen</p>
              </div>
              <div id="chartJumlahSuratBerdasarkanBentukDokumen"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dashboardSponsorship">
      <dashboard-sponsorship-view v-if="dashboardSponsorship"></dashboard-sponsorship-view>
    </div>
    <div v-if="dashboardVendor">
      <dashboard-vendor-view v-if="dashboardVendor"></dashboard-vendor-view>
    </div>
  </Layout>
</template>
